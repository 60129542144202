





































import Vue from "vue";
import CharacterRoomArtPvp from "../components/CharacterRoomArtPvp.vue";
import { mapActions, mapState } from "vuex";
import Web3 from "web3";

export default Vue.extend({
  props: ["characterId", "room", "selectedWeaponId", "selectedCharacterId", "isRequest", "handleRequestFight","handleCancelFight", "isCancel"],
  components: {
    CharacterRoomArtPvp,
  },
  methods: {
    ...mapActions(["fetchCharacters", "requestFight"]),
    // handleRequestFight() {
    //   //@ts-ignore
    //   this.requestFight({
    //     roomId: this.room.id,
    //     weaponId: this.selectedWeaponId,
    //     characterId: this.selectedCharacterId,
    //   });
    // },
  },
  computed: {
    ...mapState(["characters"]),
    matchReward() {
      return Web3.utils.fromWei(this.room.matchReward, "ether");
    },
  },
  async mounted() {
    //@ts-ignore
    await this.fetchCharacters([this.characterId]);
  },
});
