var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltipHtml(_vm.character)),expression:"tooltipHtml(character)"}],ref:"el",staticClass:"character-art"},[_c('div',{staticClass:"containerTop"},[_c('span',{class:_vm.characterTrait.toLowerCase() + '-icon circle-element'}),_c('div',[_c('div',{staticClass:"name-lvl-container"},[(!_vm.portrait)?_c('div',{staticClass:"name black-outline",attrs:{"title":_vm.getCleanCharacterName(_vm.character.id)}},[_vm._v(" "+_vm._s('#'+ _vm.character.id)+" ")]):_vm._e(),(!_vm.portrait)?_c('div',{staticClass:"lv"},[_vm._v(" Lv."),_c('span',{},[_vm._v(_vm._s(_vm.character.level + 1))])]):_vm._e()])])]),_c('div',{staticClass:"placeholder d-flex align-items-start justify-content-center"},[_c('div',{class:{
        'w-100': _vm.portrait,
        'h-100': !_vm.isMarket,
        'h-75': _vm.isMarket,
      },style:({
        'background-image': 'url(' + _vm.getCharacterArt(_vm.character) + ')',
        'z-index': 999
      })}),_c('div',{staticClass:"traitOfCharacter",style:({
      'background-image': 'url(' + _vm.getCharacterTrait(_vm.character) + ')',
      'height': '89px'
      })})]),(!_vm.allLoaded)?_c('div',{staticClass:"loading-container"},[_c('i',{staticClass:"fas fa-spinner fa-spin"})]):_vm._e(),_c('div',{class:{ 'market-bot': !_vm.portrait }},[_c('div',{staticClass:"score-id-container"},[(!_vm.portrait)?_c('div',{staticClass:"black-outline"},[_c('span',{staticClass:"white"},[_vm._v(_vm._s(_vm.getCleanCharacterName(_vm.character.id)))])]):_vm._e()]),_c('div',{staticClass:"score-id-container"},[(!_vm.portrait)?_c('div',{staticClass:"black-outline"},[_vm._v(" Owner: "),_c('span',{staticClass:"ownerText"},[_vm._v(_vm._s(_vm.renderOwner(this.room.owner)))])]):_vm._e()]),_c('div',{staticClass:"cost"},[_c('div'),_vm._v(" "+_vm._s(this.matchReward))])])])}
var staticRenderFns = []

export { render, staticRenderFns }